.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  max-width: 100%;
  transition: all .2s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
  filter: drop-shadow(12px 20px 8px rgb(0 0 0 / 0.4));
  cursor: pointer;
}

.logo-link {
  width: 50%;
}
